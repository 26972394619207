import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("simple-form", { scopedSlots: _vm._u([{ key: "default", fn: function(ref) {
    var handleSubmit = ref.handleSubmit;
    return [_c("a-modal", { attrs: { "after-close": _vm.afterModalClose, "title": "Move Cluster Mapping" }, scopedSlots: _vm._u([{ key: "footer", fn: function() {
      return [_c("a-button", { on: { "click": _vm.onCancelClick } }, [_vm._v("Cancel")]), _c("a-button", { attrs: { "type": "primary" }, on: { "click": function($event) {
        return handleSubmit(_vm.submit);
      } } }, [_vm._v("Move")])];
    }, proxy: true }], null, true), model: { value: _vm.visible, callback: function($$v) {
      _vm.visible = $$v;
    }, expression: "visible" } }, [_c("a-row", { attrs: { "gutter": 24 } }, [_c("a-col", { staticClass: "pb-3", attrs: { "span": 12 } }, [_c("text-input", { key: "clusterName", attrs: { "form-item": "", "label": "From Cluster", "value": _vm.entity && _vm.entity.clusterName, "rules": "required", "disabled": true } })], 1), _c("a-col", { staticClass: "pb-3", attrs: { "span": 12 } }, [_c("select-input", { attrs: { "form-item": "", "label": "To Cluster", "data-source": _vm.clusters, "source": "id", "source-label": "clusterName", "option-filter": _vm.filterClusterNameOption, "default-active-first-option": "", "rules": "required" }, on: { "change": _vm.onChangeClusterId } })], 1)], 1)], 1)];
  } }]) });
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "MoveClusterMappingL2",
  inject: ["resourceProps"],
  data() {
    const [clusterTransProps, clusterProps] = this.resourceProps;
    const { apiUrl: apiUrl2 } = clusterTransProps;
    return {
      apiUrl: apiUrl2,
      clusterTransProps,
      clusterProps,
      visible: false,
      clusterL2Url: `${this.apiUrl}/web-analytics/cluster-l2-trans`,
      entity: {},
      newClusterId: null
    };
  },
  computed: {
    clusters() {
      return this.clusterProps.crud.getList();
    }
  },
  mounted() {
    this.visible = true;
    this.id = this.$route.params.id;
    this.entity = this.clusterTransProps.crud.getEntity(this.id);
    this.storeValues(this.entity);
  },
  methods: {
    onCancelClick() {
      this.visible = false;
    },
    afterModalClose() {
      this.$router.push(this.clusterTransProps.redirectRoute);
    },
    onChangeClusterId(value) {
      this.newClusterId = value;
    },
    async submit() {
      await this.clusterTransProps.crud.submitPartialEntity({
        resourceName: this.clusterTransProps.resourceName,
        id: this.entity.id,
        data: [
          {
            op: "replace",
            path: "/ClusterId",
            value: this.newClusterId
          }
        ]
      }).then(() => {
        this.clusterTransProps.crud.fetchList();
      }, (err) => {
        this.showErrorMsg(err);
      });
      this.visible = false;
    },
    storeValue(resourceKey, value) {
      this.entity = {
        ...this.entity,
        [resourceKey]: value
      };
      this.clusterTransProps.crud.updateEntity("editForm", {
        resourceKey,
        value
      });
    },
    storeValues(entity) {
      Object.entries(entity).forEach(([resourceKey, value]) => this.storeValue(resourceKey, value));
    },
    filterClusterNameOption(option) {
      return option.clusterName != this.entity.clusterName;
    },
    showErrorMsg(err) {
      this.$notification["error"]({
        message: err.response.data.message
      });
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var MoveClusterMappingL2 = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "move-cluster-mappingL2" }, [_c("resource", { attrs: { "name": [
    "web-analytics.cluster-l2-trans",
    "web-analytics.common.clusters"
  ], "api-url": _vm.apiUrl, "redirect-route": ["/web-analytics/cluster-l2-trans"] } }, [_c("move-cluster-mapping-l2")], 1)], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    MoveClusterMappingL2
  },
  data() {
    return {
      apiUrl
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
